import React, { useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Routes } from '../../../../utils';
import NavBarItem from './NavBarItem';
import NavBarItemWithOverlay from './NavBarItemWithOverlay';
import NavBarDropdown from './NavBarDropdown';
import JDELogo from '../../../../assets/JDElogo.png';
import { useAuthContext } from '../../../Auth/AuthProvider';
import { PermissionEnum } from '../../../../types/PermissionEnum';
import 'material-design-icons/iconfont/material-icons.css';
import './NavBar.scss';
import { useStoreContext } from '../../StoreProvider';

export const NavBar = (): JSX.Element => {
    const [selectedTab, setSelectedTab] = useState<any>(window.location.pathname);
    const [state, dispatch] = useStoreContext();
    const {
        bol: { relatedBol },
    } = state;

    const isSystemAdminActive: boolean =
        selectedTab === `${Routes.SYSTEM_ADMIN_USERS.route}` ||
        selectedTab === `${Routes.SYSTEM_ADMIN_PERMISSIONS.route}` ||
        selectedTab === `${Routes.SYSTEM_ADMIN_CUSTOMERS.route}` ||
        selectedTab === `${Routes.SYSTEM_ADMIN_CUSTOMER_PARENTS.route}` ||
        selectedTab === `${Routes.SYSTEM_ADMIN_CREWS.route}` ||
        selectedTab === `${Routes.SYSTEM_ADMIN_LOFAS.route}` ||
        selectedTab === `${Routes.SYSTEM_ADMIN_PROPANTS.route}` ||
        selectedTab === `${Routes.SYSTEM_ADMIN_DRIVERS.route}` ||
        selectedTab === `${Routes.SYSTEM_ADMIN_CARRIERS.route}` ||
        selectedTab === `${Routes.SYSTEM_ADMIN_PROPANTS.route}` ||
        selectedTab === `${Routes.SYSTEM_ADMIN_SETTINGS.route}`;
    const isReportingActive: boolean = selectedTab === `${Routes.DRIVER_REPORT.route}`;
    const isProfileActive: boolean =
        selectedTab === `${Routes.PROFILE.route}` || selectedTab === `${Routes.CHANGE_PASSWORD.route}`;
    const profileHeader = (
        <div className="NavBar-ProfileIconContainer">
            <i className="material-icons NavBar-ProfileIcon">person</i>
        </div>
    );
    const authContext = useAuthContext();
    const opsAdmin = authContext.userHasPermission(PermissionEnum.Admin);
    const opsCustomer = authContext.userHasPermission(PermissionEnum.Customer);
    const opsCustomerService = authContext.userHasPermission(PermissionEnum.CustomerService);

    // const canViewUsers = authContext.userHasPermission(PermissionEnum.ViewUsers);
    // const canViewPermissions = authContext.userHasPermission(PermissionEnum.ViewPermissions);
    // const canViewLofas = authContext.userHasPermission(PermissionEnum.ViewLOFA);
    // const canViewCustomers = authContext.userHasPermission(PermissionEnum.ViewCustomer);
    // const canViewCrews = authContext.userHasPermission(PermissionEnum.ViewCrew);
    // const canViewProppants = authContext.userHasPermission(PermissionEnum.ViewProppant);
    // const canViewDrivers = authContext.userHasPermission(PermissionEnum.ViewDriver);
    // const canViewCustomerParent = authContext.userHasPermission(PermissionEnum.ViewCustomerParent);
    // const canViewCarriers = authContext.userHasPermission(PermissionEnum.ViewDriverCompany);
    // const canViewSetting = authContext.userHasPermission(PermissionEnum.ViewSettings);

    const isAdmin = opsAdmin;
    const isCustomerService = opsCustomerService;

    // console.log('NavBar - relatedBol', selectedTab, relatedBol);
    // if (selectedTab !== Routes.BILLS_LADING.route && relatedBol > 0) {
    //     setSelectedTab(Routes.BILLS_LADING.route);
    // }

    useEffect(() => {
        if (selectedTab !== Routes.BILLS_LADING.route && relatedBol > 0) {
            setSelectedTab(Routes.BILLS_LADING.route);
        }
    }, [relatedBol]);
    // const canViewJob = authContext.userHasPermission(PermissionEnum.ViewJob);
    // const canEditJob = authContext.userHasPermission(PermissionEnum.EditJob);
    // const canViewMessages = authContext.userHasPermission(PermissionEnum.ViewMessages);

    return (
        <Navbar
            expand="lg"
            className="NavBar-Container"
            onSelect={(eventKey: string | null): void => {
                setSelectedTab(eventKey);
            }}
        >
            <Navbar.Brand href={Routes.PURCHASE_ORDERS.route}>
                <img alt="" src={JDELogo} className="mlogo d-inline-block align-top" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto NavBar-linksContainer">
                    {/* {
                        <NavBarItem
                            href={Routes.OPEN_ORDERS.route}
                            title="Open Orders"
                            isActive={selectedTab === Routes.OPEN_ORDERS.route}
                            isDisabled={false}
                        />
                    } */}
                    {
                        <NavBarItem
                            href={Routes.PURCHASE_ORDERS.route}
                            title="Purchase Orders"
                            isActive={selectedTab === Routes.PURCHASE_ORDERS.route}
                            isDisabled={false}
                        />
                    }
                    {
                        <NavBarItem
                            href={Routes.BILLS_LADING.route}
                            title="Bills of Lading"
                            isActive={selectedTab === Routes.BILLS_LADING.route}
                            isDisabled={false}
                        />
                    }
                    {
                        <NavBarItem
                            href={Routes.INVOICES.route}
                            title="My Invoices"
                            isActive={selectedTab === Routes.INVOICES.route}
                            isDisabled={false}
                        />
                    }
                    {
                        <NavBarItem
                            href={Routes.REPORTS.route}
                            title="My Reports"
                            isActive={selectedTab === Routes.REPORTS.route}
                            isDisabled={false}
                        />
                    }
                    {/* <NavBarItem
                        href={Routes.JOB_MANAGEMENT.route}
                        title="Job Management"
                        isActive={
                            selectedTab === Routes.JOB_MANAGEMENT.route ||
                            selectedTab.startsWith(Routes.JOB_PROFILE.route) ||
                            selectedTab.startsWith(Routes.JOB_LOADS.route)
                        }
                        isDisabled={!(canViewJob || canEditJob)}
                    /> */}
                    {/* <NavBarItem
                        href={Routes.MESSAGES.route}
                        title="Messages"
                        isActive={
                            selectedTab === Routes.MESSAGES.route ||
                            selectedTab.startsWith(Routes.MESSAGES_CREATE.route) ||
                            selectedTab.startsWith(Routes.MESSAGES_WITH_DRIVER_ID.route) ||
                            selectedTab.startsWith(Routes.MESSAGES_WITH_ID.route)
                        }
                        isDisabled={!authContext.userHasPermission(PermissionEnum.ViewMessages)}
                    /> */}
                    {/* <NavBarDropdown
                        title="Reporting"
                        isActive={isReportingActive}
                        // routes={[Routes.DRIVER_REPORT, Routes.POWER_BI_REPORT]}
                        routes={[
                            Routes.POWER_BI_REPORT,
                            {
                                title: 'Sandbox Reporting',
                                type: 'external',
                                route:
                                    'https://app.powerbi.com/groups/me/apps/0cd3ab7f-50a5-4e39-82da-edeec28d4c4a/reports/03305737-310c-4198-a249-3a5aa2202d58/ReportSectionc4e11989ce20a4919ca0',
                            },
                        ]}
                    /> */}
                    {
                        <NavBarItem
                            href={Routes.CONTACT.route}
                            title="Contact"
                            isActive={selectedTab === Routes.CONTACT.route}
                            isDisabled={false}
                        />
                    }

                    {(isAdmin || isCustomerService) && (
                        <NavBarDropdown
                            title={Routes.SYSTEM_ADMIN.title}
                            isActive={isSystemAdminActive}
                            routes={[
                                Routes.SYSTEM_ADMIN_USERS,
                                //Routes.SYSTEM_ADMIN_PERMISSIONS,
                                //Routes.SYSTEM_ADMIN_LOFAS,
                                //Routes.SYSTEM_ADMIN_CUSTOMERS,
                                //Routes.SYSTEM_ADMIN_CUSTOMER_PARENTS,
                                //Routes.SYSTEM_ADMIN_DRIVERS,
                                //Routes.SYSTEM_ADMIN_CARRIERS,
                                //Routes.SYSTEM_ADMIN_PROPANTS,
                                //Routes.SYSTEM_ADMIN_CREWS,
                                //Routes.SYSTEM_ADMIN_SETTINGS,
                            ]}
                        />
                    )}
                </Nav>
                <Nav>
                    {/* <NavBarItemWithOverlay>
                        <Nav.Link href={Routes.EMAIL.route} disabled>
                            <i className="material-icons NavBar-NotificationIcon">email</i>
                        </Nav.Link>
                    </NavBarItemWithOverlay>*/}
                    <NavBarDropdown header={profileHeader} isActive={isProfileActive} routes={[Routes.HOME]} />
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default NavBar;
